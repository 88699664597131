import React, { useState, useEffect } from 'react';
import classNames from 'classnames';

import logo from '../../assets/images/logo.png';

import ReactGA from 'react-ga';

import styles from './navbar.module.css';

import { changeLocale, Link } from "gatsby-plugin-intl";

import { FaBars, FaTimes, FaChevronDown, FaChevronUp } from "react-icons/fa";

const NavBar = () => {

    const [open, setOpen] = useState(false);
    const [openList, setOpenList] = useState(false);
    
    useEffect(() => {
        // console.log("Aquiiio: ", window.location.pathname + window.location.search)
        ReactGA.initialize('UA-39322055-1');

        ReactGA.pageview(window.location.pathname + window.location.search)
    }, [])

    return (
        <div className={styles.containerpos}>

            {/* SideBar Menu Mobile */}

            <ul className={classNames(styles.leftMenuContainer, open ? styles.openSidBar : null)}>
                <li><Link to="/sobre">Sobre Nós</Link></li>
                <li>
                    <Link to="/servicos">Serviços</Link>

                    {openList ? <FaChevronUp className={styles.iconList} onClick={() => setOpenList(false)} /> : <FaChevronDown className={styles.iconList} onClick={() => setOpenList(true)} /> }
                </li>

                {openList &&
                    <div className={styles.boxMoreListItem}>
                        <li><Link to="/pesquisa-de-mercado">Pesquisa De Mercado</Link></li>
                        <li><Link to="/avaliacao-de-empresas">Avaliação De Empresas</Link></li>
                        <li><Link to="/business-plan">Business Plan</Link></li>
                        <li><Link to="/fusoes-e-aquisicoes">Consultoria em M&A</Link></li>
                        <li><Link to="/consultoria-em-planejamento-estrategico">Planejamento Estratégico</Link></li>
                        <li><Link to="/consultoria-empresarial">Consultoria Empresarial</Link></li>
                    </div>
                }

                <li><Link to="/clientes">Clientes</Link></li>
                <li><Link to="/consultoria-pme">Consultoria PME</Link></li>
                <li><Link to="/contato">Contato</Link></li>
                <li>
                    <a href={() => false} aria-label="pt-BR" onClick={() => changeLocale('pt-BR')}><img src="/br.png" alt="" /></a>
                </li>
                <li>
                    <a href={() => false} aria-label="en" onClick={() => changeLocale('en')}><img src="/us.png" alt="" /></a>
                </li>
	        </ul>

            {/* Fim do SideBar Menu Mobile */}

            <div className={styles.boxContainerPosition}>
                <div className={styles.barContainer}>
                    <Link className={styles.logo} to='/'>
                        <img className={styles.logo} src={logo} alt="Logomarca GlobalTrevo Consulting" />
                    </Link>

                    {open ? <FaTimes onClick={() => {setOpen(false); setOpenList(false)}} className={styles.menuIcon} /> : <FaBars onClick={() => setOpen(!open)} className={styles.menuIcon} />}

                    {/* NavBar Menu Desktop */}

                    <div className={styles.NavBarDesk}>
                        <Link to="/sobre">Sobre Nós</Link>
                        <div className={styles.dropdown}>
                            <Link to="/servicos">Serviços</Link>

                            <div className={styles.dropdownContent}>
                                <div className={styles.boxDescriptionSubMenu}>
                                    <Link to="/avaliacao-de-empresas" className={styles.descriptionSubMenu}>VALUATION DE EMPRESAS</Link>
                                </div>

                                <div className={styles.boxDescriptionSubMenu}>
                                    <Link to="/business-plan" className={styles.descriptionSubMenu}>BUSINESS PLAN</Link>
                                </div>

                                <div className={styles.boxDescriptionSubMenu}>
                                    <Link to="/pesquisa-de-mercado" className={styles.descriptionSubMenu}>PESQUISA DE MERCADO</Link>
                                </div>

                                <div className={styles.boxDescriptionSubMenu}>
                                    <Link to="/consultoria-empresarial" className={styles.descriptionSubMenu}>CONSULTORIA EMPRESARIAL</Link>
                                </div>

                                <div className={styles.boxDescriptionSubMenu}>
                                    <Link to="/fusoes-e-aquisicoes" className={styles.descriptionSubMenu}>CONSULTORIA EM M&A</Link>
                                </div>

                                <div className={styles.boxDescriptionSubMenu}>
                                    <Link to="/consultoria-em-planejamento-estrategico" className={styles.descriptionSubMenu}>PLANEJAMENTO ESTRATÉGICO</Link>
                                </div>
                            </div>
                        </div>
                        <Link to="/clientes">Clientes</Link>
                        <Link to="/consultoria-pme">Consultoria PME</Link>
                        <Link to="/contato">Contato</Link>
                        <a href={() => false} aria-label="pt-BR" onClick={() => changeLocale('pt-BR')}><img src="/br.png" alt="" /></a>
                        <a href={() => false} aria-label="en" onClick={() => changeLocale('en')}><img src="/us.png" alt="" /></a>
                    </div>

                    {/* Fim NavBar Menu Desktop */}
                </div>
            </div>
        </div>
    );
}

export default NavBar;
