// /**
//  * SEO component that queries for data with
//  *  Gatsby's useStaticQuery React hook
//  *
//  * See: https://www.gatsbyjs.org/docs/use-static-query/
//  */

import React from "react"

import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ description, lang, meta, title, renderGTM }) {
    const { site } = useStaticQuery(
        graphql`
            query {
                site {
                    siteMetadata {
                        title
                        description
                        author
                    }
                }
            }
        `
    )

    const metaDescription = description || site.siteMetadata.description

    return (
        <Helmet
            htmlAttributes={{ lang }}
            title={title}
            titleTemplate={`%s | ${site.siteMetadata.title}`}
            link={[
                {
                    rel: "stylesheet",
                    href: "https://fonts.googleapis.com/css?family=Staatliches",
                },
                {
                    rel: "stylesheet",
                    href: "https://cdn.jsdelivr.net/npm/uikit@3.2.3/dist/css/uikit.min.css",
                },
            ]}
            script={[
                {
                    src: "https://cdnjs.cloudflare.com/ajax/libs/uikit/3.2.0/js/uikit.min.js",
                },
                {
                    src: "https://cdn.jsdelivr.net/npm/uikit@3.2.3/dist/js/uikit-icons.min.js",
                },
                {
                    src: "https://cdnjs.cloudflare.com/ajax/libs/uikit/3.2.0/js/uikit.js",
                },
                {
                    src: `/js/snap.js`,
                },
                {
                    src: `/js/drawer.js`,
                }
            ]}
            meta={[
                {
                    name: `description`,
                    content: metaDescription,
                },
                {
                    property: `og:title`,
                    content: title,
                },
                {
                    property: `og:description`,
                    content: metaDescription,
                },
                {
                    property: `og:type`,
                    content: `website`,
                },
                {
                    name: `twitter:card`,
                    content: `summary`,
                },
                {
                    name: `twitter:creator`,
                    content: site.siteMetadata.author,
                },
                {
                    name: `twitter:title`,
                    content: title,
                },
                {
                    name: `twitter:description`,
                    content: metaDescription,
                },
            ].concat(meta)}
        >
            {/* <!-- Google Tag Manager --> */}
            <script>
                {`
                    (function(w,d,s,l,i){
                        w[l]=w[l]||[];
                        w[l].push({"gtm.start": new Date().getTime(),event:"gtm.js"});
                        var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';
                        j.async=true;
                        j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;
                        f.parentNode.insertBefore(j,f);
                    })(window,document,'script','dataLayer','GTM-KRFLD6D');
                `}
            </script>
            {/* <!-- End Google Tag Manager --> */}

            {/* Google Tag Manager */}
            <script async src="https://www.googletagmanager.com/gtag/js?id=G-E9SECC3DGZ"></script>

            <noscript>{'<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-KRFLD6D" height="0" width="0" style="display:none;visibility:hidden"></iframe>'}</noscript>

            <script>
                {"window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments)}; gtag('js', new Date()); gtag('config', 'G-E9SECC3DGZ'); gtag('config', 'GTM-KRFLD6D');"}
            </script>

            {/* Microsoft Advertising */}
            <script>
                {'(function(w,d,t,r,u){var f,n,i;w[u]=w[u]||[],f=function(){var o={ti:"27014766"};o.q=w[u],w[u]=new UET(o),w[u].push("pageLoad")},n=d.createElement(t),n.src=r,n.async=1,n.onload=n.onreadystatechange=function(){var s=this.readyState;s&&s!=="loaded"&&s!=="complete"||(f(),n.onload=n.onreadystatechange=null)},i=d.getElementsByTagName(t)[0],i.parentNode.insertBefore(n,i)})(window,document,"script","//bat.bing.com/bat.js","uetq");'}
            </script>

            <script>
                {`
                    window.uetq = window.uetq || [];
                    window.uetq.push('event', 'api.whatsapp.com', {});
                `}
            </script>
        </Helmet>
    )
}

SEO.defaultProps = {
    lang: `en`,
    meta: [],
    description: ``,
}

SEO.propTypes = {
    description: PropTypes.string,
    lang: PropTypes.string,
    meta: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.string.isRequired,
    renderGTM: PropTypes.bool
}

export default SEO
