import React from 'react';

import styles from './footer.module.css';

function footer() {
  let now = new Date()

  return (
    <div className={styles.footerContainer}>
      <p>© {now.getFullYear()} - GlobalTrevo Consulting - Todos os direitos reservados</p>
    </div>
  );
}

export default footer;