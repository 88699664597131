import React from "react"
import PropTypes from "prop-types"

import SEO from "./seo";
import NavBar from "./navBar";
import Footer from "./footerBox";

import { useIntl } from "gatsby-plugin-intl";

const Layout = ({ children, titlePage, renderGTM }) => {
    const intl = useIntl();

    return (
        <>
            <SEO title={titlePage} lang={intl.locale === 'en' ? "en" : "pt-BR"} renderGTM={renderGTM} />

            <NavBar />

            {children}

            <Footer />
            
            {/* <!-- Google Tag Manager (noscript) --> */}
            <noscript dangerouslySetInnerHTML={{
                __html: `
                    <iframe
                        src="https://www.googletagmanager.com/ns.html?id=GTM-KRFLD6D"
                        height="0"
                        width="0"
                        style="display:none;visibility:hidden"></iframe>
                `.trim()
            }} />
            {/* <!-- End Google Tag Manager (noscript) --> */}
        </>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
    titlePage: PropTypes.string,
    renderGTM: PropTypes.bool
}

export default Layout
